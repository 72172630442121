*, html {
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #FFF;
}


