@media (max-width: 931px) {
    .grid-specified-date-range {
        order: 2
    }
}

@media (max-width: 1900px) {
    .form-container::before, .form-container::after {
        content: '';
        width: 100%;
		order: 1;
	}
	.MuiGrid-spacing-xs-6 {
		width: calc(100% + 35px) !important;
	}
}

@media (max-width: 931px) and (min-width: 600px) {
    .grid-three-inputs {
        width: 540px;
    }
    .form-three-group-second {
        width: 222px;
    }
}

@media (max-width: 607px) and (min-width: 600px) {
    .input-specified-date-range, .input-form-of-transaction {
        min-width: 218px !important;
    }
    .input-three-group-first {
        min-width: 215px !important;
    }
}

@media (max-width: 735px) and (min-width: 600px) {
    .buttons-search-container {
        width: 66.66%;
    }
    .button-export-container {
        width: 33.33%;
    }
    .button {
        width: 50% !important;
        line-height: 1 !important;
        font-size: 13px !important;
    }
    .export-excel-button {
        width: 100% !important;
        line-height: 1 !important;
        font-size: 13px !important;
    }
}

.MuiGrid-spacing-xs-6 {
    width: calc(100% + 36px) !important;
    margin: -16px !important;
}

.MuiBackdrop-root {
    opacity: 0 !important;
}
/* .grid-specified-date-range .MuiMenu-paper {
	min-height: auto !important;
} */

.MuiMenu-paper {
	min-height: 500px !important;
}

#menu-formOfTransaction .MuiMenu-paper, #menu-specified-date-range .MuiMenu-paper {
	min-height: auto !important;
}

.MuiPaper-elevation24 {
    box-shadow: 0px 0px 22px 5px rgba(0,0,0,0.54) !important;
}

.MuiAccordion-root.Mui-disabled {
    background-color: transparent !important;
}

.MuiAccordionSummary-content {
	margin:0px;
}

.MuiAccordionSummary-root>.MuiAccordionSummary-expandIcon {
	padding: 0 !important;
}

.MuiAccordionSummary-root>.MuiAccordionSummary-expandIcon.Mui-expanded {
	padding: 0 !important;
}

.MuiAccordionDetails-root {
	padding-top: 0 !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-90deg) !important;
}

